import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import LexicalEditor from '~common/components/lexical/LexicalEditor'
import { Agreement } from '~common/generated/storefront-graphql'
import clsx from 'clsx'

interface AgreementModalProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
  agreements: Agreement[]
}

export const AgreementModal: React.FC<AgreementModalProps> = ({
  isOpen,
  setOpen,
  agreements,
}) => {
  return (
    <>
      <Transition show={isOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-[75]"
          onClose={() => setOpen(false)}
        >
          <TransitionChild
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="dark:bg-slate-900 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
                  <div className="mt-4 text-center sm:mt-6 sm:text-left">
                    <DialogTitle className="text-3xl font-semibold leading-6 text-gray-900 dark:text-slate-400">
                      Terms & Conditions
                    </DialogTitle>
                  </div>
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="dark:bg-slate-900 dark:text-slate-400 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div
                    className={clsx(
                      'mt-10 w-full inline pl-1.5',
                      'sm:mx-4 sm:mt-6',
                      'text-gray-600',
                    )}
                  >
                    {agreements.map((agreement) => (
                      <div key={agreement.id} className="w-full mb-4">
                        <h2 className="dark:text-slate-300 text-md font-semibold mb-2">
                          {agreement.label}
                        </h2>
                        {agreement.agreement ? (
                          <div className="max-h-72 overflow-y-auto">
                            <LexicalEditor
                              editorContent={agreement.agreement}
                              onChange={() => {}}
                              isReadOnly={true}
                            />
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
