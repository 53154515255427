import React from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { SearchPropsType } from '~common/types'

export const SearchInput: React.FC<SearchPropsType> = ({
  onSearch,
  onClear,
}) => {
  return (
    <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
      <div className="relative flex flex-1">
        <label htmlFor="search-field" className="sr-only">
          Search
        </label>
        <div className="relative w-full border rounded-md overflow-hidden border-slate-400">
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400 dark:text-slate-400 ml-2"
            aria-hidden="true"
          />

          <input
            id="search-field"
            className="block h-full w-full border-0 py-4 pl-8 pr-0 text-gray-900 dark:bg-slate-900 dark:text-slate-400 placeholder:text-gray-400 focus:ring-0 sm:text-sm pr-2"
            placeholder="Search..."
            type="search"
            name="search"
            onChange={onSearch}
          />
        </div>
      </div>
    </div>
  )
}
