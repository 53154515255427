import React from 'react'
import clsx from 'clsx'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'

export const Pagination: React.FC<{
  pageIndex: number
  pageSize: number
  pageCount: number
  itemCount: number
  hasNext: boolean
  hasPrevious: boolean
  onChangeIndex: (idx: number) => void
  onNext: () => void
  onPrevious: () => void
}> = ({
  pageIndex,
  pageSize,
  pageCount,
  itemCount,
  hasNext,
  hasPrevious,
  onChangeIndex,
  onNext,
  onPrevious,
}) => {
  const displayMaxSurroundingPages = 3
  const leftLimit = Math.max(0, pageIndex - displayMaxSurroundingPages)
  const rightLimit = Math.min(
    pageCount - 1,
    pageIndex + displayMaxSurroundingPages,
  )

  const leftSideItems = Math.max(0, pageIndex - leftLimit)
  const rightSideItems = Math.min(rightLimit - pageIndex)
  return (
    <div className="flex items-center justify-between border-t border-gray-200 dark:border-slate-500 bg-white dark:bg-slate-800 px-4 py-3 sm:px-0">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          className={clsx(
            'relative inline-flex items-center rounded-md border px-4 py-2 text-sm font-medium',
            'bg-white text-gray-700 border-gray-300 dark:bg-slate-800 dark:text-slate-400 dark:border-slate-500 hover:bg-gray-50 dark:hover:bg-slate-700',
          )}
          onClick={onPrevious}
          disabled={!hasPrevious}
        >
          Previous
        </button>
        <button
          className={clsx(
            'relative ml-3 inline-flex items-center rounded-md border px-4 py-2 text-sm font-medium',
            'bg-white text-gray-700 border-gray-300 dark:bg-slate-800 dark:text-slate-400 dark:border-slate-500 hover:bg-gray-50 dark:hover:bg-slate-700',
          )}
          onClick={onNext}
          disabled={!hasNext}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:px-2 sm:flex sm:flex-1 items-center sm:justify-between">
        <div className="mr-2">
          <p className="text-sm text-gray-700 dark:text-slate-400">
            Showing{' '}
            <span className="font-medium">{pageIndex * pageSize + 1}</span> to{' '}
            <span className="font-medium">
              {(pageIndex + 1) * pageSize <= itemCount
                ? (pageIndex + 1) * pageSize
                : itemCount}
            </span>{' '}
            of <span className="font-medium">{itemCount}</span> results
          </p>
        </div>
        <div className="ml-2">
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm border border-gray-300 dark:border-slate-500"
            aria-label="Pagination"
          >
            <button
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 dark:text-slate-400 dark:bg-slate-800 dark:hover:bg-slate-700 dark:ring-slate-400"
              onClick={onPrevious}
              disabled={!hasPrevious}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {leftLimit > 0 ? (
              <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 focus:outline-offset-0">
                ...
              </span>
            ) : null}
            {leftSideItems > 0
              ? new Array(leftSideItems).fill('').map((_, idx) => {
                  return (
                    <button
                      key={`page-left-${idx}`}
                      aria-current="page"
                      aria-label={`Page ${leftLimit + idx + 1}`}
                      className={clsx(
                        'relative items-center px-4 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex dark:text-slate-400 dark:bg-slate-800 dark:hover:bg-slate-700 dark:ring-slate-400',
                        {
                          'inline-flex ': idx === 0,
                          'hidden md:inline-flex ': idx > 0,
                        },
                      )}
                      onClick={() => onChangeIndex(leftLimit + idx)}
                    >
                      {leftLimit + idx + 1}
                    </button>
                  )
                })
              : null}
            <button
              key={`page-${pageIndex}`}
              aria-current="page"
              aria-label={`Page ${pageIndex + 1}`}
              className="relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-primary-100 text-primary-500 dark:bg-slate-800 dark:hover:bg-slate-700 dark:text-slate-400"
              onClick={() => onChangeIndex(pageIndex)}
            >
              {pageIndex + 1}
            </button>

            {rightSideItems > 0
              ? new Array(rightSideItems).fill('').map((_, idx) => {
                  return (
                    <button
                      key={`page-right-${idx}`}
                      aria-current="page"
                      aria-label={`Page ${pageIndex + idx + 2}`}
                      className={clsx(
                        'relative items-center px-4 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 dark:text-slate-400 dark:hover:bg-slate-700 focus:z-20 focus:outline-offset-0',
                        {
                          'inline-flex': idx === 0,
                          'hidden sm:inline-flex': idx > 0,
                        },
                      )}
                      onClick={() => onChangeIndex(pageIndex + idx + 1)}
                    >
                      {pageIndex + idx + 2}
                    </button>
                  )
                })
              : null}
            {rightLimit < pageCount - 1 ? (
              <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 dark:text-slate-400 focus:outline-offset-0">
                ...
              </span>
            ) : null}
            <button
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 hover:bg-gray-50 dark:bg-slate-800 dark:text-slate-400 dark:ring-slate-400 focus:z-20 focus:outline-offset-0"
              onClick={onNext}
              disabled={!hasNext}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  )
}
