import { Subscription, SubscriptionInterval } from '~common/components/product'
import {
  SUBSCRIPTIONS_BIWEEKLY,
  SUBSCRIPTIONS_MONTHLY,
  SUBSCRIPTIONS_PIF,
  SUBSCRIPTIONS_WEEKLY,
} from '~common/components/product/constants'
import {
  OrderLine,
  AcceptBlueSubscription,
  StripeSubscription,
  FacetValue,
} from '~common/generated/admin-graphql'

type SubscriptionTupleType = [] | [Subscription, Subscription] | [Subscription]

export const getSubscriptionTuple = (
  s: SubscriptionTupleType | undefined,
): SubscriptionTupleType => {
  if (!s) {
    console.log('No subscriptions available')
    return []
  }
  const recurringFee = s[0]

  if (!recurringFee) {
    return []
  }

  const registrationFee = s[1]

  if (registrationFee) {
    return [recurringFee, registrationFee]
  } else {
    return [recurringFee]
  }
}

/**
 * returns empty array if no subscriptions exist, or an array with either 1 or 2 subscriptions
 * the first subscription is the subscription with recurring value,
 * and the second subscription is the optional recurring registration fee
 * @param line
 */
export const extractSubscriptions = (
  line: OrderLine,
): SubscriptionTupleType => {
  const subscriptions = line.acceptBlueSubscriptions || line.stripeSubscriptions
  // we assume bi-weekly will be represented as intervalCount: 2, interval: week
  return getSubscriptionTuple(
    subscriptions as unknown as SubscriptionTupleType | undefined,
  )
}

export const convertPreviewToOrderLine = (
  line: OrderLine,
  subscriptions: Subscription[],
) => {
  const [recurringFee, registrationFee] = getSubscriptionTuple(
    subscriptions as unknown as SubscriptionTupleType,
  )
  const {
    amountDueNow,
    priceIncludesTax,
    recurring: { amount, startDate, endDate, interval, intervalCount },
  } = recurringFee
  //  const unitPrice = priceIncludingTax ? line.unitPriceWithTax : line.unitPrice
  //  const linePrice = priceIncludingTax ? line.linePriceWithTax : line.linePrice
  // const proration = line.linePrice - (initialFee?.recurring?.amount ?? 0)
  line.acceptBlueSubscriptions =
    subscriptions as unknown as AcceptBlueSubscription[]
  line.stripeSubscriptions = subscriptions as unknown as StripeSubscription[]
  return line
}

export const convertSubscriptionFacetToInterval = (
  subscriptions: FacetValue[],
): { interval: SubscriptionInterval; intervalCount: number }[] => {
  return subscriptions?.map((s) => {
    switch (s.code) {
      case SUBSCRIPTIONS_BIWEEKLY:
        return { interval: SubscriptionInterval.week, intervalCount: 2 }
      case SUBSCRIPTIONS_WEEKLY:
        return { interval: SubscriptionInterval.week, intervalCount: 1 }

      case SUBSCRIPTIONS_PIF:
        return { interval: SubscriptionInterval.year, intervalCount: 1 }
      default:
        return { interval: SubscriptionInterval.month, intervalCount: 1 }
    }
  })
}
