import { useCallback } from 'react'
import clsx from 'clsx'
import { Pill } from '~ui/Pill'

import { capitalizeFirstLetter } from '~common/utils/format/capitalizeFirstLetter'
import { SimpleProductVariantCardProps, SubscriptionInterval } from './types'

const getDuration = (duration: string) => {
  switch (duration) {
    case 'month':
      return 'mth'
    case 'week':
      return 'wk'
    case 'bi-weekly':
      return 'bi-wk'
    case 'year':
      return 'yr'
  }
}

const getDurationText = (
  interval: SubscriptionInterval,
  intervalCount: number,
  isTrial: boolean,
) => {
  // options[1] is the duration option group; options0[0] is the payment frequency
  let str =
    interval === SubscriptionInterval.year ? (
      <>
        Pay
        <br />
        in
        <br />
        Full
      </>
    ) : (
      intervalCount
    )
  return (
    <>
      {intervalCount > 0 ? (
        <div
          className={clsx(
            'w-20 h-20 p-4 mx-2 rounded-md flex flex-col justify-center items-center',
            {
              'bg-orange-300 bg-opacity-10 dark:bg-opacity-40':
                interval === SubscriptionInterval.biweekly,
              'bg-green-300 bg-opacity-10 dark:bg-opacity-40':
                interval === SubscriptionInterval.week,
              'bg-fuchsia-200 bg-opacity-20 dark:bg-opacity-40':
                interval === SubscriptionInterval.month,
              'bg-yellow-200 bg-opacity-10 dark:bg-opacity-40':
                interval === SubscriptionInterval.year && !isTrial,
              'bg-blue-500 bg-opacity-10 dark:bg-opacity-40':
                interval === SubscriptionInterval.year && isTrial,
            },
          )}
        >
          <span
            className={clsx(
              'font-bold text-center text-black dark:text-slate-300',
              {
                'text-4xl': !isTrial && interval !== SubscriptionInterval.year,
                'text-md': isTrial || interval === SubscriptionInterval.year,
                'text-blue-600': isTrial,
              },
            )}
          >
            {isTrial ? 'Trial' : str}
          </span>
          {interval === SubscriptionInterval.year ? null : (
            <span className="font-bold text-xs text-center text-black dark:text-slate-400">
              {capitalizeFirstLetter(`${interval}`)}
            </span>
          )}
        </div>
      ) : (
        <div className="w-20 h-20 p-4 mx-2 rounded-md bg-sky-100 bg-opacity-5 flex flex-col justify-center items-center">
          <span className="font-bold text-xl text-primary-600">Trial</span>
        </div>
      )}
    </>
  )
}

export const SimpleProductVariantCard: React.FC<
  SimpleProductVariantCardProps
> = ({
  interval,
  intervalCount,
  type,
  className,
  highlight,
  trial,
  disabled,
  checked,
  name,
  value,
  showCheckbox,
  onClick,
}) => {
  const handleClick = useCallback(
    (e: React.SyntheticEvent<HTMLDivElement>) => {
      // e.stopPropagation()

      onClick(interval, intervalCount, type)
    },
    [checked, onClick],
  )
  let durationFrequency

  switch (interval) {
    case SubscriptionInterval.month:
      durationFrequency = `${intervalCount} Monthly recurring payment${
        intervalCount > 1 ? 's' : ''
      }`
      break
    case SubscriptionInterval.week:
      durationFrequency = `${intervalCount} Weekly recurring payment${
        intervalCount > 1 ? 's' : ''
      }`
      break
    case SubscriptionInterval.year:
      durationFrequency = 'One time payment'
      break
  }
  let intervalCountVal =
    interval === SubscriptionInterval.year
      ? 'Pay in Full'
      : capitalizeFirstLetter(`${interval}`)

  if (trial) {
    durationFrequency = ''
    intervalCountVal = 'Trial'
  }
  return (
    <div
      className={clsx(
        'border hover:border-primary-400 rounded-lg p-1 relative',
        {
          'bg-gray-50 text-primary-contrast-500 border-primary-400 dark:bg-slate-800':
            checked,
          'text-inherit bg-white border-gray-400 dark:bg-slate-900 dark:border-slate-500':
            !checked,
          'cursor-pointer': !disabled,
        },
        className,
      )}
      onClick={handleClick}
    >
      <div className={clsx('flex justify-between items-start', {})}>
        <div className="grow-1 shrink-1 flex gap-1 py-2">
          {showCheckbox ? (
            <div className="ml-2 flex items-center">
              <input
                id={name}
                type="radio"
                checked={checked}
                name={name}
                value={value}
                disabled={disabled}
                onChange={() => {}}
                className={clsx(
                  'w-4 h-4 focus:ring-2',
                  'text-primary-600 border-gray-300 focus:ring-gray-200',
                  'dark:focus:ring-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600',
                )}
                tabIndex={-1}
              />
            </div>
          ) : null}
          {getDurationText(interval, intervalCount, !!trial)}
          <div className="flex justify-start flex-col items-start">
            <p
              className={clsx(
                'font-semibold text-center text-ellipsis line-clamp-2 dark:text-slate-400',
              )}
            >
              {intervalCountVal}
            </p>
            <p
              className={clsx({
                'text-sm text-gray-500 dark:text-slate-300': checked,
                'text-sm text-gray-600 dark:text-slate-400': !checked,
              })}
            >
              {durationFrequency}
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-end items-end">
          <div className="flex flex-row justify-end flex-wrap">
            {highlight && <Pill variant="highlight">{highlight}</Pill>}
          </div>
        </div>
      </div>

      <div
        className={clsx(
          'border-0 rounded-xl absolute left-0 right-0 top-0 bottom-0 bg-white/60',
          {
            hidden: !disabled,
          },
        )}
      />
    </div>
  )
}
