import React from 'react'
import { AllProductsList } from './AllProductsList'
import { SearchInput } from '../../search/SearchInput'
import { GalleryPropsType } from '~common/types'
import { DataTableQueryFilter } from '~common/components/table/QueryDataTable'
import { LogicalOperator, Product } from '~common/generated/admin-graphql'
import { useDebouncedCallback } from 'use-debounce'
import { AllProductVariantsList } from './AllProductVariantsList'

export const ProductGallery: React.FC<GalleryPropsType<any>> = ({
  items,
  itemCount,
  filter,
  selected,
  onSelect,
  loading,
  idExtractor = (item: any) => item.id,
  onSetOptions,
  isProductGallery,
}) => {
  const [queryFilter, setQueryFilter] = React.useState<
    DataTableQueryFilter<any>
  >({
    filterOperator: filter?.filterOperator || LogicalOperator.Or,
    skip: filter?.skip || 0,
    take: filter?.take || 12,
  })
  const [filterValue, setFilterValue] = React.useState<string>('')

  const onLoad = useDebouncedCallback(
    () => {
      onSetOptions(queryFilter)
    },
    300,
    {
      maxWait: 600,
      leading: true,
      trailing: true,
    },
  )

  React.useEffect(() => {
    onLoad()
  }, [queryFilter])

  const handleSearch = React.useCallback((value: string) => {
    setQueryFilter({
      ...queryFilter,
      filter: {
        name: {
          contains: value.toLowerCase(),
        },
      },
    })
  }, [])

  const handleChangeSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setFilterValue(value)
      if (value.length > 2) {
        handleSearch(value)
      } else if (filterValue.length > 0 && !value.length) {
        // reset
        setQueryFilter({
          ...queryFilter,
          filter: undefined,
        })
      }
    },
    [filterValue, queryFilter],
  )

  const handleChangePageSize = React.useCallback(
    (size: number) => {
      setQueryFilter({
        ...queryFilter,
        take: size,
      })
    },
    [queryFilter],
  )

  const handleChangePageIndex = React.useCallback(
    (idx: number) => {
      setQueryFilter({
        ...queryFilter,
        skip: idx,
      })
    },
    [queryFilter],
  )

  const handleClearSearch = React.useCallback(() => {
    setFilterValue('')
    setQueryFilter({
      ...queryFilter,
      filter: undefined,
    })
  }, [handleChangeSearch])

  return (
    <div className="w-full my-6 flex flex-col gap-4">
      <SearchInput onSearch={handleChangeSearch} onClear={handleClearSearch} />
      {isProductGallery ? (
        <AllProductsList
          items={items}
          itemCount={itemCount}
          selected={selected}
          setSelected={onSelect}
          onChangePageIndex={handleChangePageIndex}
          onChangePageSize={handleChangePageSize}
        />
      ) : (
        <AllProductVariantsList
          items={items}
          itemCount={itemCount}
          selected={selected}
          setSelected={onSelect}
          onChangePageIndex={handleChangePageIndex}
          onChangePageSize={handleChangePageSize}
        />
      )}
    </div>
  )
}
